import React from 'react';
import { motion } from 'framer-motion';
import { FaYoutube, FaDesktop, FaMobile, FaVideo, FaMoneyBillWave, FaNetworkWired, FaWifi } from 'react-icons/fa';
import Navbar from '../Navbar/Navbar';
import styles from './Tutorials.module.css';

export const Tutorials = () => {
  const tutorials = [
    {
      title: "How To Buy Proxy",
      description: "Learn how to purchase and set up your proxy on speedbuy.xyz",
      link: "https://youtu.be/GiNHMC6UgRs",
      icon: <FaVideo />,
      category: "Getting Started"
    },
    {
      title: "Add Money To Account",
      description: "Step-by-step guide to add funds to your account",
      link: "https://youtu.be/izhRpVEPs94",
      icon: <FaMoneyBillWave />,
      category: "Account Management"
    },
    {
      title: "SOCKS-5 on Desktop",
      description: "Configure SOCKS-5 proxy on Windows/Linux systems",
      link: "https://youtu.be/QkAo5xt52X8",
      icon: <FaDesktop />,
      category: "Desktop Setup"
    },
    {
      title: "SOCKS-5 on Mobile",
      description: "Set up SOCKS-5 proxy on Android/iOS devices",
      link: "https://youtu.be/nDGLqbOaVE0",
      icon: <FaMobile />,
      category: "Mobile Setup"
    },
    {
      title: "L2TP/PPTP on Windows",
      description: "Configure L2TP/PPTP connection on Windows",
      link: "https://youtu.be/sWVxGLjbEpg",
      icon: <FaNetworkWired />,
      category: "VPN Setup"
    },
    {
      title: "L2TP/PPTP on Router",
      description: "Set up L2TP/PPTP on your router for whole network",
      link: "https://youtu.be/GSefGL7S7Xw",
      icon: <FaWifi />,
      category: "Network Setup"
    }
  ];

  return (
    <div className={styles.root}>
      <Navbar />
      <motion.div 
        className={styles.container}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className={styles.header}>
          <motion.h1
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            Setup Tutorials
          </motion.h1>
          <motion.p
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            Step-by-step video guides for all platforms
          </motion.p>
        </div>

        <div className={styles.tutorialGrid}>
          {tutorials.map((tutorial, index) => (
            <motion.div
              key={index}
              className={styles.tutorialCard}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 + 0.5 }}
              whileHover={{ y: -5, transition: { duration: 0.2 } }}
            >
              <div className={styles.cardIcon}>
                {tutorial.icon}
              </div>
              <div className={styles.cardContent}>
                <span className={styles.category}>{tutorial.category}</span>
                <h3>{tutorial.title}</h3>
                <p>{tutorial.description}</p>
                <a 
                  href={tutorial.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.watchButton}
                >
                  <FaYoutube />
                  <span>Watch Tutorial</span>
                </a>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
}

export default Tutorials;
