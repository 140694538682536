import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { doc, getDoc, updateDoc, increment } from "firebase/firestore";
import { blogDb } from "./config/firebaseWebAppConfig";
import { FiEdit2, FiThumbsUp } from 'react-icons/fi';
import styles from "./BlogDetails.module.css";
import { useBlogAuth } from './context/BlogAuthContext';
import { isAuthorizedAdmin } from './utils/authUtils';

const BlogDetails = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const { blogUser } = useBlogAuth();
  const isAdmin = blogUser && isAuthorizedAdmin(blogUser.email);
  const [reactions, setReactions] = useState({ likes: 0 });
  const [userReactions, setUserReactions] = useState({});

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const docRef = doc(blogDb, "blogs", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setBlog(docSnap.data());
          setReactions({
            likes: docSnap.data().likes || 0
          });
          // Increment view count
          await updateDoc(docRef, {
            views: increment(1)
          });
        } else {
          console.error("Blog not found");
        }
      } catch (error) {
        console.error("Error fetching blog:", error);
      } finally {
        setLoading(false);
      }
    };

    // Load user's previous reactions from localStorage
    const savedReactions = localStorage.getItem(`blog-${id}-reactions`);
    if (savedReactions) {
      setUserReactions(JSON.parse(savedReactions));
    }

    fetchBlog();
  }, [id]);

  const handleReaction = async (type) => {
    try {
      const docRef = doc(blogDb, "blogs", id);
      
      // Toggle reaction
      const newUserReactions = { ...userReactions };
      newUserReactions[type] = !newUserReactions[type];
      setUserReactions(newUserReactions);
      
      // Save to localStorage
      localStorage.setItem(`blog-${id}-reactions`, JSON.stringify(newUserReactions));

      // Update in database
      await updateDoc(docRef, {
        [type]: increment(newUserReactions[type] ? 1 : -1)
      });

      // Update local state
      setReactions(prev => ({
        ...prev,
        [type]: prev[type] + (newUserReactions[type] ? 1 : -1)
      }));

    } catch (error) {
      console.error("Error updating reaction:", error);
      alert("Failed to update reaction");
    }
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <div className={styles.loadingSpinner} />
      </div>
    );
  }

  if (!blog) {
    return (
      <div className={styles.container}>
        <h1>Blog post not found</h1>
      </div>
    );
  }

  const formatDate = (date) => {
    return new Date(date?.toDate()).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <div className={styles.container}>
      <article>
        <header className={styles.articleHeader}>
          <h1 className={styles.title}>{blog.title}</h1>
          <div className={styles.metadata}>
            <span>Published {formatDate(blog.createdAt)}</span>
            {blog.lastModified && (
              <>
                <div className={styles.divider} />
                <span>Updated {formatDate(blog.lastModified)}</span>
              </>
            )}
            {isAdmin && (
              <>
                <div className={styles.divider} />
                <span>{blog.views || 0} views</span>
              </>
            )}
            <div className={styles.divider} />
            <span>{reactions.likes || 0} likes</span>
          </div>
        </header>

        <div 
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: blog.content }} 
        />

        <div className={styles.reactionBar}>
          <button 
            className={`${styles.reactionButton} ${userReactions.likes ? styles.active : ''}`}
            onClick={() => handleReaction('likes')}
          >
            <FiThumbsUp /> {reactions.likes}
          </button>
        </div>
      </article>

      {isAdmin && (
        <Link to={`/blog/edit/${id}`} className={styles.editButton}>
          <FiEdit2 /> Edit Post
        </Link>
      )}
    </div>
  );
};

export default BlogDetails;
