import React from 'react';
import { motion } from 'framer-motion';
import { FaBolt, FaClock, FaCalendarAlt, FaNetworkWired } from 'react-icons/fa';
import Navbar from '../Navbar/Navbar';
import styles from './PricingPage.module.css';

export const PricingPage = () => {
    const pricingData = [
        {
            title: "Monthly Packages",
            subtitle: "SOCKS5",
            icon: <FaCalendarAlt />,
            popular: true,
            prices: [
                { speed: "100mbps", price: 150, highlight: true },
                { speed: "60mbps", price: 130 },
                { speed: "50mbps", price: 100 },
                { speed: "40mbps", price: 90 },
                { speed: "30mbps", price: 70 },
                { speed: "20mbps", price: 50 }
            ]
        },
        {
            title: "15-Day Packages",
            subtitle: "SOCKS5",
            icon: <FaClock />,
            prices: [
                { speed: "100mbps", price: 120 },
                { speed: "60mbps", price: 100 },
                { speed: "50mbps", price: 80 },
                { speed: "40mbps", price: 70 },
                { speed: "30mbps", price: 60 },
                { speed: "20mbps", price: 40 }
            ]
        },
        {
            title: "Weekly Packages",
            subtitle: "SOCKS5",
            icon: <FaBolt />,
            prices: [
                { speed: "100mbps", price: 100 },
                { speed: "60mbps", price: 90 },
                { speed: "50mbps", price: 70 },
                { speed: "40mbps", price: 60 },
                { speed: "30mbps", price: 50 },
                { speed: "20mbps", price: 30 }
            ]
        },
        {
            title: "VPN Packages",
            subtitle: "PPTP/L2TP",
            icon: <FaNetworkWired />,
            special: true,
            prices: [
                { speed: "100mbps", price: 300, highlight: true },
                { speed: "50mbps", price: 200 },
                { speed: "30mbps", price: 150 },
                { speed: "20mbps", price: 120 }
            ]
        }
    ];

    const containerVariants = {
        hidden: { 
            opacity: 0,
            y: 20
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.6,
                when: "beforeChildren",
                staggerChildren: 0.2
            }
        }
    };

    const cardVariants = {
        hidden: { 
            opacity: 0,
            y: 50,
            scale: 0.9
        },
        visible: {
            opacity: 1,
            y: 0,
            scale: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 0.8
            }
        },
        hover: {
            y: -10,
            scale: 1.02,
            transition: {
                type: "spring",
                stiffness: 300,
                damping: 10
            }
        }
    };

    const priceItemVariants = {
        hidden: { opacity: 0, x: -20 },
        visible: { 
            opacity: 1, 
            x: 0,
            transition: {
                type: "spring",
                stiffness: 100
            }
        }
    };

    const headerVariants = {
        hidden: { opacity: 0, y: -50 },
        visible: { 
            opacity: 1, 
            y: 0,
            transition: {
                type: "spring",
                duration: 1,
                bounce: 0.3
            }
        }
    };

    return (
        <div className={styles.root}>
            <Navbar />
            <motion.div 
                className={styles.mainContent}
                variants={containerVariants}
                initial="hidden"
                animate="visible"
            >
                <motion.div 
                    className={styles.header}
                    variants={headerVariants}
                >
                    <h1>Pricing Plans</h1>
                    <p>Choose the perfect package for your needs</p>
                </motion.div>

                <motion.div 
                    className={styles.pricingContainer}
                    variants={containerVariants}
                >
                    {pricingData.map((packageData, index) => (
                        <motion.div
                            key={index}
                            className={`${styles.pricingCard} 
                                ${packageData.popular ? styles.popular : ''} 
                                ${packageData.special ? styles.special : ''}`}
                            variants={cardVariants}
                            whileHover="hover"
                            custom={index}
                        >
                            <motion.div 
                                className={styles.cardHeader}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: index * 0.2 }}
                            >
                                <motion.div 
                                    className={styles.iconWrapper}
                                    animate={{ 
                                        rotateY: [0, 360],
                                        transition: {
                                            duration: 3,
                                            repeat: Infinity,
                                            ease: "linear",
                                            delay: index * 0.2
                                        }
                                    }}
                                >
                                    {packageData.icon}
                                </motion.div>
                                <h3>{packageData.title}</h3>
                                <p className={styles.subtitle}>{packageData.subtitle}</p>
                                {!packageData.special && (
                                    <motion.div 
                                        className={styles.ftpNote}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ delay: 0.5 + index * 0.1 }}
                                    >
                                        FTP Access: for ৳30 extra. Available FTP(ICC, Circle, Dflix, Dhakaflix, etc)
                                    </motion.div>
                                )}
                                {packageData.popular && (
                                    <motion.span 
                                        className={styles.popularBadge}
                                        animate={{ 
                                            scale: [1, 1.1, 1],
                                            transition: {
                                                duration: 2,
                                                repeat: Infinity,
                                                ease: "easeInOut"
                                            }
                                        }}
                                    >
                                        Most Popular
                                    </motion.span>
                                )}
                            </motion.div>
                            <ul className={styles.pricingList}>
                                {packageData.prices.map((item, idx) => (
                                    <motion.li 
                                        key={idx} 
                                        className={`${styles.pricingItem} 
                                            ${item.highlight ? styles.highlight : ''}`}
                                        variants={priceItemVariants}
                                        custom={idx}
                                        whileHover={{
                                            scale: 1.02,
                                            x: 5,
                                            transition: { duration: 0.2 }
                                        }}
                                    >
                                        <span className={styles.speed}>{item.speed}</span>
                                        <span className={styles.price}>
                                            <span className={styles.currency}>৳</span>
                                            {item.price}
                                        </span>
                                    </motion.li>
                                ))}
                            </ul>
                        </motion.div>
                    ))}
                </motion.div>
            </motion.div>
        </div>
    );
};

export default PricingPage;
