import React from 'react';
import { Link } from 'react-router-dom';
import { FaTelegram } from 'react-icons/fa';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.footerSection}>
          <h3>About Us</h3>
          <p>Providing best BDIX bypass proxy service with high-quality, reliable SOCKS5, L2TP, and PPTP proxies in Bangladesh.</p>
        </div>
        <div className={styles.footerSection}>
          <h3>Quick Links</h3>
          <ul>
            <li><Link to="/pricing">Pricing</Link></li>
            <li><Link to="/reviews">Reviews</Link></li>
            <li><Link to="/tutorials">Tutorials</Link></li>
            <li><Link to="/contact">Contact</Link></li>
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
          </ul>
        </div>
        <div className={styles.footerSection}>
          <h3>Contact</h3>
          <ul>
            <li>
              <a 
                href="https://t.me/speedbuy_xyz" 
                target="_blank" 
                rel="noopener noreferrer"
                className={styles.telegramLink}
              >
                <FaTelegram /> @speedbuy_xyz
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.footerBottom}>
        <p>&copy; {new Date().getFullYear()} SpeedBuy. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
