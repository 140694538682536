import React, { createContext, useState, useContext, useRef } from 'react';

const RouterContext = createContext();

export const RouterProvider = ({ children }) => {
  const [availableIPs, setAvailableIPs] = useState([]);
  const [lastFetchTime, setLastFetchTime] = useState(null);
  const [lastBalanceState, setLastBalanceState] = useState({ balance: 0, proxyCount: 0 });
  const hasInitiallyFetchedRef = useRef(false);

  const updateRouterData = (ips) => {
    setAvailableIPs(ips);
    setLastFetchTime(Date.now());
  };

  const updateBalanceState = (balance, proxyCount) => {
    setLastBalanceState({ balance, proxyCount });
  };

  return (
    <RouterContext.Provider value={{
      availableIPs,
      lastFetchTime,
      lastBalanceState,
      updateRouterData,
      updateBalanceState,
      hasInitiallyFetchedRef
    }}>
      {children}
    </RouterContext.Provider>
  );
};

export const useRouter = () => useContext(RouterContext);
