import React from 'react';
import { motion } from 'framer-motion';
import { FaRocket, FaShieldAlt, FaClock, FaUsersCog, FaLightbulb } from 'react-icons/fa';
import Navbar from '../Navbar/Navbar';
import styles from './AboutUs.module.css';
import { Link } from 'react-router-dom';

export const AboutUs = () => {
    const fadeIn = {
        initial: { opacity: 0, y: 20 },
        animate: { opacity: 1, y: 0 },
        transition: { duration: 0.6 }
    };

    const features = [
        { icon: <FaRocket />, title: "High Speed", description: "Optimized proxies for faster connections" },
        { icon: <FaShieldAlt />, title: "Secure", description: "Enhanced security and privacy protection" },
        { icon: <FaClock />, title: "24/7 Access", description: "Round-the-clock availability" },
        { icon: <FaUsersCog />, title: "Custom Setup", description: "Tailored proxy configurations" }
    ];

    return (
        <div className={styles.root}>
            <Navbar />
            <div className={styles.aboutUs}>
                {/* Hero Section */}
                <motion.section 
                    className={styles.hero}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.8 }}
                >
                    <h1>SpeedBuy</h1>
                    <p className={styles.subtitle}>Best BDIX bypass Socks5 Proxy Provider</p>
                    <div className={styles.glowBar}></div>
                </motion.section>

                {/* Features Grid */}
                <motion.section 
                    className={styles.featuresGrid}
                    initial="hidden"
                    animate="visible"
                    variants={{
                        visible: { transition: { staggerChildren: 0.1 } }
                    }}
                >
                    {features.map((feature, index) => (
                        <motion.div
                            key={index}
                            className={styles.featureCard}
                            variants={{
                                hidden: { opacity: 0, y: 20 },
                                visible: { opacity: 1, y: 0 }
                            }}
                        >
                            <div className={styles.featureIcon}>{feature.icon}</div>
                            <h3>{feature.title}</h3>
                            <p>{feature.description}</p>
                        </motion.div>
                    ))}
                </motion.section>

                {/* Mission Statement */}
                <motion.section 
                    className={styles.mission}
                    {...fadeIn}
                >
                    <div className={styles.missionContent}>
                        <FaLightbulb className={styles.missionIcon} />
                        <h2>Our Mission</h2>
                        <p>Empowering users with seamless BDIX bypass solutions to enhance browsing, streaming, and overall internet experience.</p>
                    </div>
                </motion.section>

                {/* Services Section */}
                <motion.section 
                    className={styles.services}
                    {...fadeIn}
                >
                    <h2>What We Offer</h2>
                    <div className={styles.servicesGrid}>
                        <div className={styles.serviceCard}>
                            <h3>Socks5 Proxies</h3>
                            <ul>
                                <li>Real-time generation</li>
                                <li>Customizable speed limits</li>
                                <li>Multiple validity periods</li>
                            </ul>
                        </div>
                        <div className={styles.serviceCard}>
                            <h3>L2TP/PPTP Services</h3>
                            <ul>
                                <li>VPN protocol support</li>
                                <li>Enhanced security options</li>
                                <li>Wide device compatibility</li>
                            </ul>
                        </div>
                        <div className={styles.serviceCard}>
                            <h3>Support</h3>
                            <ul>
                                <li>24/7 Technical assistance</li>
                                <li>Quick issue resolution</li>
                                <li>Expert guidance</li>
                            </ul>
                        </div>
                    </div>
                </motion.section>

                {/* Call to Action */}
                <motion.section 
                    className={styles.cta}
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.5 }}
                >
                    <div className={styles.ctaContent}>
                        <h2>Ready to Get Started?</h2>
                        <p>Join thousands of satisfied users who trust SpeedBuy</p>
                        <Link to="/signup">
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                className={styles.ctaButton}
                            >
                                Sign Up Now
                            </motion.button>
                        </Link>
                    </div>
                </motion.section>
            </div>
        </div>
    );
};

export default AboutUs;
