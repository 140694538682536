import { Navigate } from 'react-router-dom';
import { useBlogAuth } from './context/BlogAuthContext';

const ProtectedRoute = ({ children }) => {
    const { blogUser } = useBlogAuth();

    if (!blogUser) {
        return <Navigate to="/blog/login" replace />; // Updated path
    }

    return children;
};

export default ProtectedRoute;
