import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { FaEnvelope, FaArrowLeft } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import { auth} from "../../config/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import styles from './ForgotPassword.module.css'; // Import the module CSS
import 'react-toastify/dist/ReactToastify.css';

export const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [resetAttempts, setResetAttempts] = useState(() => 
        parseInt(localStorage.getItem('resetAttempts') || '0')
    );
    const [lastResetTime, setLastResetTime] = useState(() => 
        parseInt(localStorage.getItem('lastResetTime') || '0')
    );
    const [hourlyAttempts, setHourlyAttempts] = useState(() => 
        parseInt(localStorage.getItem('hourlyResetAttempts') || '0')
    );
    const [hourlyResetStart, setHourlyResetStart] = useState(() => 
        parseInt(localStorage.getItem('hourlyResetStart') || '0')
    );

    useEffect(() => {
        localStorage.setItem('resetAttempts', resetAttempts.toString());
        localStorage.setItem('lastResetTime', lastResetTime.toString());
        localStorage.setItem('hourlyResetAttempts', hourlyAttempts.toString());
        localStorage.setItem('hourlyResetStart', hourlyResetStart.toString());
    }, [resetAttempts, lastResetTime, hourlyAttempts, hourlyResetStart]);

    const checkRateLimit = () => {
        const now = Date.now();
        const oneHourAgo = now - 3600000; // 1 hour in milliseconds
        const cooldownPeriod = now - 60000; // 60 seconds in milliseconds

        // Reset hourly counter if it's been more than an hour
        if (hourlyResetStart < oneHourAgo) {
            setHourlyAttempts(0);
            setHourlyResetStart(now);
        }

        // Check hourly limit (5 attempts per hour)
        if (hourlyAttempts >= 5) {
            const timeUntilReset = (hourlyResetStart + 3600000) - now;
            const minutesLeft = Math.ceil(timeUntilReset / 60000);
            toast.error(`Maximum password reset attempts reached. Please try again in ${minutesLeft} minutes.`);
            return false;
        }

        // Check 60-second cooldown
        if (lastResetTime > cooldownPeriod) {
            const secondsLeft = Math.ceil((lastResetTime + 60000 - now) / 1000);
            toast.error(`Please wait ${secondsLeft} seconds before requesting another reset link.`);
            return false;
        }

        // Show remaining attempts in success message
        const remainingAttempts = 5 - hourlyAttempts;
        if (remainingAttempts <= 2) {
            toast.warning(`You have ${remainingAttempts} reset attempts remaining for this hour.`);
        }

        return true;
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
    
        if (!email) {
            toast.error("Please enter your email address.");
            return;
        }
    
        if (!checkRateLimit()) return;
    
        setIsLoading(true);
        try {
            await sendPasswordResetEmail(auth, email);
    
            // Update rate limiting counters
            const now = Date.now();
            setLastResetTime(now);
            setResetAttempts(prev => prev + 1);
            setHourlyAttempts(prev => prev + 1);
            if (!hourlyResetStart) {
                setHourlyResetStart(now);
            }
    
            toast.success("If this email is associated with an account, a reset link has been sent.");
            setEmail("");
        } catch (error) {
            if (error.code === 'auth/user-not-found') {
                // Generic message for security
                toast.success("If this email is associated with an account, a reset link has been sent.");
            } else {
                toast.error("Error sending reset email. Please try again later.");
            }
        } finally {
            setIsLoading(false);
        }
    };
    

    return (
        <div className={styles.root}>
            <ToastContainer theme="dark" />
            
            <Link to="/login" className={styles.backLink}>
                <FaArrowLeft /> Back to Login
            </Link>

            <motion.div 
                className={styles.container}
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3 }}
            >
                <div className={styles.formBox}>
                    <motion.div 
                        className={styles.formHeader}
                        initial={{ y: -20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.2 }}
                    >
                        <h1>Reset Password</h1>
                        <p>Enter your email to receive reset instructions</p>
                    </motion.div>

                    <form onSubmit={handleResetPassword}>
                        <div className={styles.inputWrapper}>
                            <FaEnvelope className={styles.inputIcon} />
                            <input
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>

                        <motion.button 
                            type="submit"
                            className={styles.resetButton}
                            whileTap={{ scale: 0.95 }}
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <div className={styles.loadingSpinner} />
                            ) : (
                                "Send Reset Link"
                            )}
                        </motion.button>
                    </form>

                    <div className={styles.helpText}>
                        <p>Remember your password? <Link to="/login">Sign in</Link></p>
                        <p>Don't have an account? <Link to="/signup">Sign up</Link></p>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default ForgotPassword;
