import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { FaStar, FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import styles from './ReviewsPage.module.css';
import Navbar from '../Navbar/Navbar';
import { reviewsDb } from '../../../BlogPages/config/firebaseWebAppConfig';

export const ReviewsPage = React.memo(() => {
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('latest');

    useEffect(() => {
        const fetchReviews = async () => {
            setLoading(true);
            
            try {
                const reviewsRef = collection(reviewsDb, 'reviews');
                const reviewsQuery = query(
                    reviewsRef,
                    orderBy('createdAt', 'desc')
                );

                const querySnapshot = await getDocs(reviewsQuery);
                const reviewsData = querySnapshot.docs.map(doc => {
                    const data = doc.data();
                    const date = data.createdAt?.toDate();
                    return {
                        id: doc.id,
                        ...data,
                        formattedDate: date ? date.toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        }) : 'Unknown Date'
                    };
                });
                
                setReviews(reviewsData);
            } catch (error) {
                console.error('Error fetching reviews:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchReviews();
    }, []);

    const renderStars = (rating) => (
        <div className={styles.starRating}>
            {[1, 2, 3, 4, 5].map((star) => (
                <FaStar
                    key={star}
                    className={`${styles.star} ${rating >= star ? styles.selected : ''}`}
                />
            ))}
        </div>
    );

    const filterReviews = () => {
        let filteredReviews = [...reviews];
        switch (filter) {
            case 'highest':
                return filteredReviews.sort((a, b) => b.rating - a.rating);
            case 'lowest':
                return filteredReviews.sort((a, b) => a.rating - b.rating);
            default:
                return filteredReviews;
        }
    };

    return (
        <div className={styles.root}>
            <Navbar />
            <motion.div 
                className={styles.reviewsPage}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <div className={styles.header}>
                    <motion.h1
                        initial={{ y: -20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.2 }}
                    >
                        Customer Reviews
                    </motion.h1>
                    <select 
                        value={filter} 
                        onChange={(e) => setFilter(e.target.value)}
                        className={styles.filterSelect}
                    >
                        <option value="latest">Latest First</option>
                        <option value="highest">Highest Rated</option>
                        <option value="lowest">Lowest Rated</option>
                    </select>
                </div>

                {loading ? (
                    <div className={styles.loadingContainer}>
                        <div className={styles.loadingDots}>
                            <span></span><span></span><span></span>
                        </div>
                    </div>
                ) : (
                    <motion.div 
                        className={styles.reviewsGrid}
                        variants={{
                            show: {
                                transition: {
                                    staggerChildren: 0.1
                                }
                            }
                        }}
                        initial="hidden"
                        animate="show"
                    >
                        <AnimatePresence>
                            {filterReviews().map((review, index) => (
                                <motion.div
                                    key={review.id}
                                    className={styles.reviewCard}
                                    variants={{
                                        hidden: { opacity: 0, y: 20 },
                                        show: { opacity: 1, y: 0 }
                                    }}
                                    exit={{ opacity: 0, scale: 0.95 }}
                                >
                                    <div className={styles.reviewContent}>
                                        <FaQuoteLeft className={styles.quoteIcon} />
                                        <p>{review.content}</p>
                                        <FaQuoteRight className={styles.quoteIcon} />
                                    </div>
                                    {renderStars(review.rating)}
                                    <div className={styles.reviewFooter}>
                                        <h3>{review.author}</h3>
                                        <span>{review.formattedDate}</span>
                                    </div>
                                </motion.div>
                            ))}
                        </AnimatePresence>
                    </motion.div>
                )}
            </motion.div>
        </div>
    );
});

export default ReviewsPage;
