import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FaWindows, FaLinux, FaKey, FaAndroid, FaApple, FaDownload, FaTachometerAlt } from 'react-icons/fa';
import Navbar from '../Navbar/Navbar';
import styles from './Tools.module.css';

export const Tools = () => {
  const tools = [
    {
      title: "Speed Test",
      subtitle: "Check Your Speed",
      icon: <FaTachometerAlt />,
      link: "/speedtest",
      description: "Test your BDIX and Main speeds before selecting a package",
      isInternalLink: true
    },
    {
      title: "Windows",
      subtitle: "Proxifier",
      icon: <FaWindows />,
      link: "https://www.proxifier.com/",
      description: "Connect to BDIX Bypass SOCKS5 proxy on Windows using Proxifier"
    },
    {
      title: "MacOS",
      subtitle: "Proxifier",
      icon: <FaLinux />,
      link: "https://www.proxifier.com/",
      description: "Connect to BDIX Bypass SOCKS5 proxy on MacOS using Proxifier"
    },
    {
      title: "License Keys",
      subtitle: "Proxifier Keys",
      icon: <FaKey />,
      link: "https://docs.google.com/document/d/1F5ljxgmq_iBGpG3iLQkovx-jIuySz0aMiada8Jr5UMI/edit?usp=sharing",
      description: "Get Proxifier license keys for Windows and MacOS activation"
    },
    {
      title: "Android",
      subtitle: "SocksDroid",
      icon: <FaAndroid />,
      link: "https://drive.google.com/drive/folders/1EIFiT_EDjg1-VQDDYzAaygffHrUpGTUZ?usp=drive_link",
      description: "Connect to BDIX Bypass SOCKS5 proxy on Android using SocksDroid"
    },
    {
      title: "iOS",
      subtitle: "Potatso",
      icon: <FaApple />,
      link: "https://rb.gy/vvwxod",
      description: "Connect to BDIX Bypass SOCKS5 proxy on iPhone/iPad using Potatso"
    }
  ];

  return (
    <div className={styles.root}>
      <Navbar />
      <motion.div 
        className={styles.container}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div 
          className={styles.header}
          initial={{ y: -20 }}
          animate={{ y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <h1>Essential Tools</h1>
          <p>Download the necessary tools for your proxy setup</p>
        </motion.div>

        <div className={styles.toolsGrid}>
          {tools.map((tool, index) => (
            <motion.div
              key={index}
              className={styles.toolCard}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 + 0.5 }}
              whileHover={{ y: -5, transition: { duration: 0.2 } }}
            >
              <div className={styles.toolIcon}>{tool.icon}</div>
              <div className={styles.toolInfo}>
                <h3>{tool.title}</h3>
                <span>{tool.subtitle}</span>
                <p>{tool.description}</p>
              </div>
              {tool.isInternalLink ? (
                <Link to={tool.link} className={styles.downloadButton}>
                  <FaTachometerAlt />
                  <span>Open Speed Test</span>
                </Link>
              ) : (
                <a 
                  href={tool.link} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className={styles.downloadButton}
                >
                  <FaDownload />
                  <span>Download</span>
                </a>
              )}
            </motion.div>
          ))}
        </div>

        <motion.div 
          className={styles.note}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1 }}
        >
          <p>Need help with setup? Check out our <a href="/tutorials">tutorials</a>!</p>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Tools;
