import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Login } from './components/auth/Login';
import { Home } from './components/Homepage/Home/Home';
import { Signup } from './components/auth/Signup';
import { ForgotPassword } from './components/auth/ForgotPassword';
import { Tools } from './components/Homepage/Tools/Tools';
import { Contact } from './components/Homepage/Contacts/Contact';
import { Tutorials } from './components/Homepage/Tutorials/Tutorials';
import { PricingPage } from './components/Homepage/Pricing/PricingPage';
import { ReviewsPage } from './components/Homepage/ReviewsPage/ReviewsPage';
import SpeedTestPage from './components/Homepage/SpeedTest/SpeedTestPage';
import { AboutUs } from './components/Homepage/AboutUs/AboutUs';
// import PrivacyPolicy from './components/Homepage/PrivacyPolicy/PrivacyPolicy';

import { NotFound } from './components/404/NotFound';
import WriteBlog from './BlogPages/WriteBlog';
import BlogList from './BlogPages/BlogList';
import BlogDetails from './BlogPages/BlogDetails';
import EditBlog from './BlogPages/EditBlog';
import ProtectedRoute from './BlogPages/ProtectedRoute';
import BlogLogin from './BlogPages/Login';
import { BlogAuthProvider } from './BlogPages/context/BlogAuthContext';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { Loading } from './components/common/Loading';
import { LoadingProvider } from './context/LoadingContext';
import { RouterProvider } from './context/RouterContext';
import { DashboardProtectedRoute } from './components/auth/ProtectedRoute';

// Lazy load components that use Firestore - Fix import paths and ensure default exports
const Dashboard = lazy(() => 
  import('./components/dashboard/Dashboard').then(module => {
    // Force browser to skip cache
    if (window.performance && window.performance.getEntriesByType) {
      window.performance.getEntriesByType('navigation').forEach(nav => {
        if (nav.type === 'back_forward') {
          window.location.reload();
        }
      });
    }
    return { default: module.Dashboard };
  })
);
const AddMoney = lazy(() => 
  import('./components/AddMoney/AddMoney').then(module => {
    // Force browser to skip cache
    if (window.performance && window.performance.getEntriesByType) {
      window.performance.getEntriesByType('navigation').forEach(nav => {
        if (nav.type === 'back_forward') {
          window.location.reload();
        }
      });
    }
    return { default: module.AddMoney };
  })
);
const Earnings = lazy(() => import('./components/Earnings/Earnings').then(module => ({ default: module.Earnings })));

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <LoadingProvider>
      <RouterProvider>
        <div className="App">
          <Router>
            <ScrollToTop />
            <Suspense fallback={<Loading />}>
              <Routes>
                {/* Main app routes */}
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/tools" element={<Tools />} />
                <Route path="/speedtest" element={<SpeedTestPage />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/tutorials" element={<Tutorials />} />
                <Route path="/pricing" element={<PricingPage />} />
                <Route path="/about" element={<AboutUs />} />
                {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} /> */}

                {/* Protected Routes */}
                <Route path="/dashboard/*" element={
                  <DashboardProtectedRoute>
                    <Dashboard />
                  </DashboardProtectedRoute>
                } />
                <Route path="/dashboard/addmoney" element={
                  <DashboardProtectedRoute>
                    <AddMoney key={Date.now()} />
                  </DashboardProtectedRoute>
                } />

                <Route path="/addmoney" element={<Navigate to="/dashboard/addmoney" replace />} />
                <Route path="/tracker" element={<Earnings />} />
                <Route path="/reviews" element={<ReviewsPage />} />

                {/* Blog routes wrapped with BlogAuthProvider */}
                <Route path="/blog/*" element={
                  <BlogAuthProvider>
                    <Routes>
                      <Route path="login" element={<BlogLogin />} />
                      <Route path="list" element={<BlogList />} />
                      <Route path=":id" element={<BlogDetails />} />
                      <Route path="write" element={
                        <ProtectedRoute>
                          <WriteBlog />
                        </ProtectedRoute>
                      } />
                      <Route path="edit/:id" element={
                        <ProtectedRoute>
                          <EditBlog />
                        </ProtectedRoute>
                      } />
                    </Routes>
                  </BlogAuthProvider>
                } />

                <Route path="*" element={<NotFound />} />
              </Routes>
            </Suspense>
          </Router>
        </div>
      </RouterProvider>
    </LoadingProvider>
  );
}

export default React.memo(App);
