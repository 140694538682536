import { auth, googleprovider, db } from "../../config/firebase";
import { signInWithEmailAndPassword, signInWithPopup, onAuthStateChanged } from "firebase/auth";
import { Navigate, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { FaGoogle, FaArrowLeft } from "react-icons/fa";
import styles from './Login.module.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import { motion } from 'framer-motion';
import { FaEnvelope, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';

export const Login = () => {
    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [loginAttempts, setLoginAttempts] = useState(() => 
        parseInt(localStorage.getItem('loginAttempts') || '0')
    );
    const [lockoutUntil, setLockoutUntil] = useState(() => {
        const stored = localStorage.getItem('lockoutUntil');
        return stored ? parseInt(stored) : null;
    });

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user && user.emailVerified) {
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        localStorage.setItem('loginAttempts', loginAttempts.toString());
    }, [loginAttempts]);

    useEffect(() => {
        if (lockoutUntil) {
            localStorage.setItem('lockoutUntil', lockoutUntil.toString());
            // Clear lockout after it expires
            const timeout = setTimeout(() => {
                setLockoutUntil(null);
                localStorage.removeItem('lockoutUntil');
                setLoginAttempts(0);
                localStorage.setItem('loginAttempts', '0');
            }, lockoutUntil - Date.now());
            
            return () => clearTimeout(timeout);
        } else {
            localStorage.removeItem('lockoutUntil');
        }
    }, [lockoutUntil]);

    if (isLoggedIn) {
        return <Navigate to="/dashboard" />;
    }

    const saveUserDetails = async (uid, username, email) => {
        const userDocRef = doc(db, "users", uid);
        const userDoc = await getDoc(userDocRef);
        if (!userDoc.exists()) {
            await setDoc(userDocRef, {
                uid,
                username,
                email,
                balance: 0 // Initialize balance
            });
        }
    };

    const handleLoginAttempt = () => {
        const now = Date.now();
        if (lockoutUntil && now < lockoutUntil) {
            // const remainingSeconds = Math.ceil((lockoutUntil - now) / 1000);
            toast.error(`Too many login attempts. Please try again after sometimes.`);
            return false;
        }

        if (loginAttempts >= 10) {
            const lockoutTime = now + 60000;
            setLockoutUntil(lockoutTime);
            setLoginAttempts(0);
            toast.error("Too many login attempts. Please try again after sometime.");
            return false;
        }

        setLoginAttempts(prev => prev + 1);
        return true;
    };

    const login = async () => {
        if (!handleLoginAttempt()) return;

        try {
            const userCredential = await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
            const user = userCredential.user;
            if (user.emailVerified) {
                const token = await user.getIdToken();
                localStorage.setItem('jwtToken', token);
                setLoginAttempts(0); // Reset attempts on successful login
                setLockoutUntil(null);
                localStorage.removeItem('lockoutUntil');
                localStorage.setItem('loginAttempts', '0');
                await saveUserDetails(user.uid, user.displayName || user.email.split("@")[0], user.email);
                toast.success("Login successful!");
            } else {
                await auth.signOut();
                toast.error("Email not verified. Please check your mailbox and verify your email before logging in.");
            }
        } catch (err) {
            console.error(err);
            toast.error("Login failed. Please check your credentials.");
        }
    };

    const loginWithGoogle = async () => {
        if (!handleLoginAttempt()) return;

        try {
            const userCredential = await signInWithPopup(auth, googleprovider);
            const user = userCredential.user;
            if (user.emailVerified) {
                await saveUserDetails(user.uid, user.displayName || user.email.split("@")[0], user.email);
                // Get the JWT token
                const token = await user.getIdToken();
                // Store the token in localStorage
                localStorage.setItem('jwtToken', token);

                toast.success("Login with Google successful!");
            } else {
                await auth.signOut();
                toast.error("Email not verified. Please check your inbox and verify your email before logging in.");
            }
        } catch (err) {
            toast.error("Google login failed: " + err.message);
        }
    };

    const fadeIn = {
        initial: { opacity: 0, y: 20 },
        animate: { opacity: 1, y: 0 },
        transition: { duration: 0.5 }
    };

    return (
        <div className={styles.root}>
            <ToastContainer position="top-right" theme="dark" />

            <Link to="/" className={styles.backLink}>
                <FaArrowLeft /> Back to Home
            </Link>

            <motion.div
                className={styles.loginContainer}
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3 }}
            >
                <motion.div
                    className={styles.formBox}
                    variants={fadeIn}
                    initial="initial"
                    animate="animate"
                >
                    <h1>Welcome Back</h1>
                    <p className={styles.subtitle}>Login to your account</p>

                    <div className={styles.inputGroup}>
                        <div className={styles.inputWrapper}>
                            <FaEnvelope className={styles.inputIcon} />
                            <input
                                type="email"
                                placeholder="Email"
                                value={loginEmail}
                                onChange={(e) => setLoginEmail(e.target.value)}
                            />
                        </div>

                        <div className={styles.inputWrapper}>
                            <FaLock className={styles.inputIcon} />
                            <input
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                                value={loginPassword}
                                onChange={(e) => setLoginPassword(e.target.value)}
                            />
                            <button
                                type="button"
                                className={styles.showPasswordBtn}
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                        </div>
                    </div>

                    <div className={styles.forgotPasswordContainer}>
                        <Link to="/forgot-password" className={styles.forgotPassword}>
                            Forgot Password?
                        </Link>
                    </div>

                    <motion.button
                        className={styles.loginButton}
                        onClick={login}
                        whileTap={{ scale: 0.95 }}
                    >
                        Login
                    </motion.button>

                    <div className={styles.divider}>
                        <span>OR</span>
                    </div>

                    <motion.button
                        className={styles.googleButton}
                        onClick={loginWithGoogle}
                        whileTap={{ scale: 0.95 }}
                    >
                        <FaGoogle /> Continue with Google
                    </motion.button>

                    <p className={styles.signupPrompt}>
                        Don't have an account?
                        <Link to="/signup"> Sign up</Link>
                    </p>
                </motion.div>
            </motion.div>
        </div>
    );
};

export default Login;
