import React from 'react';
import { motion } from 'framer-motion';
import { FaTelegram, FaHeadset, FaUsers, FaArrowRight } from 'react-icons/fa';
import Navbar from '../Navbar/Navbar';
import styles from './Contact.module.css';

export const Contact = () => {
  const contactLinks = [
    {
      title: "Main Telegram Group",
      description: "Join our community for updates and discussions",
      icon: <FaUsers />,
      url: "https://t.me/+IYQiMmNJL8kyZWY9",
      color: "#2AABEE"
    },
    {
      title: "Support Group",
      description: "Get help and technical support",
      icon: <FaHeadset />,
      url: "https://t.me/+EtzFLJKNrQQ4NjVl",
      color: "#0088cc"
    }
  ];

  return (
    <div className={styles.root}>
      <Navbar />
      <motion.div 
        className={styles.contactContainer}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className={styles.header}>
          <motion.h1
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2 }}
          >
            Connect With Us
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.3 }}
          >
            Join our Telegram groups for support and updates
          </motion.p>
        </div>

        <div className={styles.cardsContainer}>
          {contactLinks.map((link, index) => (
            <motion.a
              key={index}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.card}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 * (index + 1) }}
              whileHover={{ 
                scale: 1.02,
                boxShadow: `0 0 20px ${link.color}33`
              }}
            >
              <div className={styles.cardIcon} style={{ color: link.color }}>
                {link.icon}
              </div>
              <div className={styles.cardContent}>
                <h3>{link.title}</h3>
                <p>{link.description}</p>
              </div>
              <div className={styles.arrowIcon}>
                <FaArrowRight />
              </div>
            </motion.a>
          ))}
        </div>

        <motion.div 
          className={styles.telegramBanner}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
        >
          <FaTelegram className={styles.telegramIcon} />
          <div className={styles.bannerContent}>
            <h3>24/7 Support Available</h3>
            <p>Get instant help from our team on Telegram</p>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default Contact;
