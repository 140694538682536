import React, { useState } from 'react';
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { blogAuth } from './config/firebaseWebAppConfig';
import { isAuthorizedAdmin } from './utils/authUtils';
import styles from './Login.module.css';

const BlogLogin = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleGoogleLogin = async () => {
        setLoading(true);
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(blogAuth, provider);
            if (result.user) {
                console.log("Logged in email:", result.user.email); // Debug log
                const isAdmin = isAuthorizedAdmin(result.user.email);
                console.log("Is admin?", isAdmin); // Debug log
                
                if (isAdmin) {
                    navigate('/blog/write');
                } else {
                    navigate('/blog/list');
                }
            }
        } catch (error) {
            console.error("Error signing in with Google:", error);
            alert("Failed to login: " + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Blog Admin Login</h1>
            <button 
                onClick={handleGoogleLogin} 
                className={`${styles.googleButton} ${loading ? styles.loading : ''}`}
                disabled={loading}
            >
                {loading ? "Logging in..." : "Login with Google"}
            </button>
        </div>
    );
};

export default BlogLogin;
