import { initializeApp, getApps } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";

const firebaseBlogConfig = {
  apiKey: "AIzaSyAytk-DtaVCsYwiDHh8IujkAL7fKmjpFfI",
  authDomain: "speedbuy-blogs.firebaseapp.com",
  projectId: "speedbuy-blogs",
  storageBucket: "speedbuy-blogs.firebasestorage.app",
  messagingSenderId: "572090855860",
  appId: "1:572090855860:web:5d44bf9f69e658109982e1",
  measurementId: "G-DY0EWCXLNC"
};

// Initialize Blog Firebase App
const blogApp = getApps().find((app) => app.name === "blogApp") || 
  initializeApp(firebaseBlogConfig, "blogApp");

export const blogDb = getFirestore(blogApp);
export const blogAuth = getAuth(blogApp);

// Set persistence to LOCAL
setPersistence(blogAuth, browserLocalPersistence)
  .catch((error) => {
    console.error("Auth persistence error:", error);
  });

// Add reviews Firebase config
const firebaseReviewsConfig = {
  apiKey: "AIzaSyAytk-DtaVCsYwiDHh8IujkAL7fKmjpFfI",
  authDomain: "speedbuy-blogs.firebaseapp.com",
  projectId: "speedbuy-blogs",
  storageBucket: "speedbuy-blogs.firebasestorage.app",
  messagingSenderId: "572090855860",
  appId: "1:572090855860:web:5d44bf9f69e658109982e1",
  measurementId: "G-DY0EWCXLNC"
};

// Initialize Reviews Firebase App
const reviewsApp = getApps().find((app) => app.name === "reviewsApp") || 
  initializeApp(firebaseReviewsConfig, "reviewsApp");

export const reviewsDb = getFirestore(reviewsApp);