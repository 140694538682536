import React, { useState } from "react";
import "./SpeedTestPage.css";
import Navbar from '../Navbar/Navbar';
import { FaSync } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const SpeedTestPage = () => {
  const [bdixKey, setBdixKey] = useState(0);
  const [mainKey, setMainKey] = useState(0);

  const refreshBdix = () => {
    setBdixKey(prev => prev + 1);
  };

  const refreshMain = () => {
    setMainKey(prev => prev + 1);
  };

  return (
    <div className="speed-test-page">
      <Navbar />
      <h1 className="page-title">Internet Speed Test</h1>

      <div className="speed-test-section">
        {/* BDIX Speed Test */}
        <div className="speed-test-box">
          <div className="test-header">
            <h2 className="test-title">Check Your BDIX Speed</h2>
            <p className="speed-note">
              For accurate BDIX upload speed, use{' '}
              <a 
                href="https://www.speedtest.net" 
                target="_blank" 
                rel="noopener noreferrer"
                className="speed-link"
              >
                speedtest.net
              </a>
            </p>
            <button className="refresh-btn" onClick={refreshBdix} aria-label="Refresh BDIX Speed Test">
              <FaSync />
            </button>
          </div>
          <div className="bdix-speed-test">
            <iframe
              key={bdixKey}
              src="https://speedtest.com.bd/"
              title="BDIX Speed Test"
              style={{ border: "none" }}
            ></iframe>
          </div>
        </div>

        {/* Main Speed Test */}
        <div className="speed-test-box">
          <div className="test-header">
            <h2 className="test-title">Check Your Main Speed</h2>
            <button className="refresh-btn" onClick={refreshMain} aria-label="Refresh Main Speed Test">
              <FaSync />
            </button>
          </div>
          <div className="main-speed-test">
            <iframe
              key={mainKey}
              src="https://speedtest.singapore.linode.com/"
              title="Main Speed Test"
              style={{ border: "none" }}
            ></iframe>
          </div>
        </div>
      </div>

      {/* Instructions Section */}
      <div className="instructions">
      <h2>Guide for First-Time BDIX Bypass Users</h2>  
      <p>First, test your BDIX speed, then test your main speed. If your BDIX speed is higher than your main speed, you can use BDIX Bypass to convert the BDIX speed into your main speed.</p>  

      <p>The higher your BDIX speed, the more speed you can unlock. When creating a proxy on our website, choose a package that closely matches your BDIX speed.</p>  

      <p>After creating and connecting to the proxy, check your main speed again. You should now see the speed corresponding to your selected package.</p>  

      <p className="important-note"><strong>Important:</strong> Your main speed can only increase up to the speed shown in your BDIX test.{' '}
          <Link to="/pricing" style={{ color: '#ff0000', textDecoration: 'underline' }}>
            Check Our Packages
          </Link>
        </p>
      </div>
    </div>
  );
};

export default SpeedTestPage;
