import React, { useState, useRef, useMemo, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./BlogEditor.module.css";
import { blogDb, blogAuth } from "./config/firebaseWebAppConfig";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { slugify } from './utils/slugify';
import { useNavigate, Link } from 'react-router-dom';
import { signOut } from "firebase/auth";
import { isAuthorizedAdmin } from './utils/authUtils';

const WriteBlog = () => {
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const quillRef = useRef();

  useEffect(() => {
    const unsubscribe = blogAuth.onAuthStateChanged((user) => {
      if (!user) {
        console.log("No user found"); // Debug log
        navigate('/blog/list');
        return;
      }
      
      console.log("Current user email:", user.email); // Debug log
      const isAdmin = isAuthorizedAdmin(user.email);
      console.log("Is admin?", isAdmin); // Debug log
      if (!isAdmin) {
        navigate('/blog/list');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleLogout = async () => {
    try {
      await signOut(blogAuth);
      navigate('/blog/login');
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!title.trim() || !content.trim()) {
      alert("Please fill in both title and content");
      return;
    }

    setLoading(true);
    try {
      const slug = slugify(title);
      
      // Check if blog with this slug already exists
      const docRef = doc(blogDb, "blogs", slug);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        throw new Error("A blog with this title already exists");
      }

      // Use setDoc instead of addDoc to specify the document ID
      await setDoc(docRef, {
        title: title.trim(),
        content,
        createdAt: new Date(),
        slug,
        views: 0,
        likes: 100  // Initialize likes with 100
      });

      alert("Blog posted successfully!");
      navigate(`/blog/list`);
    } catch (error) {
      console.error("Error saving blog:", error);
      alert(error.message || "Failed to post blog");
    } finally {
      setLoading(false);
    }
  };

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "link"],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["clean"],
      ]
    }
  }), []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>Create New Blog Post</h1>
        <div className={styles.controls}>
          <Link to="/blog/list" className={styles.secondaryButton}>
            View All Blogs
          </Link>
          <button onClick={handleLogout} className={styles.secondaryButton}>
            Logout
          </button>
        </div>
      </div>

      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.inputGroup}>
          <label htmlFor="title" className={styles.label}>Blog Title</label>
          <input
            id="title"
            type="text"
            placeholder="Enter your blog title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className={styles.titleInput}
            required
          />
        </div>

        <div className={styles.inputGroup}>
          <label className={styles.label}>Content</label>
          <div className={styles.editor}>
            <ReactQuill
              ref={quillRef}
              value={content}
              onChange={setContent}
              modules={modules}
              placeholder="Start writing your blog content..."
              theme="snow"
            />
          </div>
        </div>

        <div className={styles.buttonGroup}>
          <button
            type="submit"
            disabled={loading}
            className={`${styles.primaryButton} ${loading ? styles.loading : ''}`}
          >
            {loading ? "Publishing..." : "Publish Blog"}
          </button>
          <Link to="/blog/list" className={styles.secondaryButton}>
            Cancel
          </Link>
        </div>
      </form>
    </div>
  );
};

export default WriteBlog;