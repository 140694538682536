import React, { createContext, useContext, useState, useEffect } from 'react';
import { blogAuth } from '../config/firebaseWebAppConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const BlogAuthContext = createContext();

export const useBlogAuth = () => {
    return useContext(BlogAuthContext);
};

export const BlogAuthProvider = ({ children }) => {
    const [blogUser, setBlogUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(blogAuth, (user) => {
            setBlogUser(user);
            setLoading(false);
            
            // If user is logged out and trying to access protected routes
            if (!user && window.location.pathname.includes('/blog/write')) {
                navigate('/blog/login');
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    const value = {
        blogUser,
        blogAuth,
        loading
    };

    return (
        <BlogAuthContext.Provider value={value}>
            {!loading && children}
        </BlogAuthContext.Provider>
    );
};
