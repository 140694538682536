import React, { createContext, useState, useContext, useEffect } from 'react';

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [initialLoadComplete, setInitialLoadComplete] = useState(() => {
    return localStorage.getItem('initialLoadComplete') === 'true';
  });

  useEffect(() => {
    localStorage.setItem('initialLoadComplete', initialLoadComplete);
  }, [initialLoadComplete]);

  return (
    <LoadingContext.Provider value={{ initialLoadComplete, setInitialLoadComplete }}>
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => useContext(LoadingContext);
