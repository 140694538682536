import React, { useState, useRef, useMemo, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./BlogEditor.module.css";
import { blogDb, blogAuth } from "./config/firebaseWebAppConfig";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useParams, useNavigate, Link } from "react-router-dom";
import { isAuthorizedAdmin } from './utils/authUtils';

const EditBlog = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const quillRef = useRef();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = blogAuth.onAuthStateChanged((user) => {
      if (!user || !isAuthorizedAdmin(user.email)) {
        navigate('/blog/list');
        return;
      }
      
      const fetchBlog = async () => {
        try {
          const docRef = doc(blogDb, "blogs", id);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const blogData = docSnap.data();
            setTitle(blogData.title);
            setContent(blogData.content);
          } else {
            alert("Blog not found");
            navigate("/blog/list");
          }
        } catch (error) {
          console.error("Error fetching blog:", error);
          alert("Error loading blog");
          navigate("/blog/list");
        } finally {
          setInitialLoading(false);
        }
      };
      fetchBlog();
    });

    return () => unsubscribe();
  }, [id, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!title.trim() || !content.trim()) {
      alert("Please fill in both title and content");
      return;
    }

    setLoading(true);
    try {
      const docRef = doc(blogDb, "blogs", id);
      await updateDoc(docRef, {
        title: title.trim(),
        content,
        lastModified: new Date()
      });

      alert("Blog updated successfully!");
      navigate(`/blog/${id}`);
    } catch (error) {
      console.error("Error updating blog:", error);
      alert(error.message || "Failed to update blog");
    } finally {
      setLoading(false);
    }
  };

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "link"],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["clean"],
      ]
    }
  }), []);

  if (initialLoading) {
    return (
      <div className={styles.container}>
        <div className={styles.loading}>Loading blog content...</div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>Edit Blog Post</h1>
        <div className={styles.controls}>
          <Link to={`/blog/${id}`} className={styles.secondaryButton}>
            View Post
          </Link>
          <Link to="/blog/list" className={styles.secondaryButton}>
            All Blogs
          </Link>
        </div>
      </div>

      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.inputGroup}>
          <label htmlFor="title" className={styles.label}>Blog Title</label>
          <input
            id="title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className={styles.titleInput}
            required
          />
        </div>

        <div className={styles.inputGroup}>
          <label className={styles.label}>Content</label>
          <div className={styles.editor}>
            <ReactQuill
              ref={quillRef}
              value={content}
              onChange={setContent}
              modules={modules}
              theme="snow"
            />
          </div>
        </div>

        <div className={styles.buttonGroup}>
          <button
            type="submit"
            disabled={loading}
            className={`${styles.primaryButton} ${loading ? styles.loading : ''}`}
          >
            {loading ? "Saving Changes..." : "Save Changes"}
          </button>
          <Link 
            to={`/blog/${id}`} 
            className={styles.secondaryButton}
          >
            Cancel
          </Link>
        </div>
      </form>
    </div>
  );
};

export default EditBlog;
