import React, { useEffect, useState } from "react";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { blogDb } from "./config/firebaseWebAppConfig";
import { Link } from "react-router-dom";
import { FiArrowRight, FiSearch, FiThumbsUp } from 'react-icons/fi';
import styles from "./BlogList.module.css";
import { useBlogAuth } from './context/BlogAuthContext';
import { isAuthorizedAdmin } from './utils/authUtils';

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const { blogUser } = useBlogAuth();
  const isAdmin = blogUser && isAuthorizedAdmin(blogUser.email);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const querySnapshot = await getDocs(collection(blogDb, "blogs"));
        const blogData = querySnapshot.docs.map(doc => ({ 
          id: doc.id, 
          ...doc.data() 
        }));
        setBlogs(blogData);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchBlogs();
  }, []);

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(blogDb, "blogs", id));
      setBlogs(blogs.filter(blog => blog.id !== id));
    } catch (error) {
      console.error("Error deleting blog:", error);
    }
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <div className={styles.loadingDots}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }

  const filteredBlogs = blogs.filter(blog =>
    blog.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.headerContent}>
          <h1 className={styles.headerTitle}>Discover Our Blog</h1>
          <p className={styles.headerSubtitle}>
            Insights, stories, and ideas from our team
          </p>
        </div>
      </header>

      <div className={styles.searchContainer}>
        <FiSearch className={styles.searchIcon} />
        <input
          type="text"
          placeholder="Search articles..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className={styles.searchInput}
        />
      </div>

      <div className={styles.blogGrid}>
        {filteredBlogs.map((blog) => (
          <article key={blog.id} className={styles.blogCard}>
            {blog.imageUrl && (
              <img 
                src={blog.imageUrl} 
                alt={blog.title}
                className={styles.blogImage}
              />
            )}
            <div className={styles.blogContent}>
              <h2 className={styles.blogTitle}>{blog.title}</h2>
              <p className={styles.blogExcerpt}>{blog.excerpt}</p>
              <div className={styles.blogFooter}>
                <div className={styles.blogStats}>
                  <span className={styles.blogDate}>
                    {new Date(blog.createdAt?.toDate()).toLocaleDateString()}
                  </span>
                  <span className={styles.statsItem}>
                    <FiThumbsUp /> {blog.likes || 0}
                  </span>
                  {isAdmin && (
                    <span className={styles.statsItem}>
                      👁 {blog.views || 0}
                    </span>
                  )}
                </div>
                <Link to={`/blog/${blog.slug || blog.id}`} className={styles.readMore}>
                  Read More <FiArrowRight />
                </Link>
              </div>
              {isAdmin && (
                <button
                  className={styles.deleteButton}
                  onClick={() => handleDelete(blog.id)}
                >
                  Delete
                </button>
              )}
            </div>
          </article>
        ))}
      </div>

      {isAdmin && (
        <Link to="/blog/write" className={styles.writeButton}>
          Write a New Post
        </Link>
      )}
    </div>
  );
};

export default BlogList;
