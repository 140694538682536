import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaBolt, FaUsersCog, FaMobileAlt, FaHeadset, FaExclamationTriangle, FaQuestionCircle, FaStar, FaQuoteLeft } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy, limit } from 'firebase/firestore';
import { reviewsDb } from '../../../BlogPages/config/firebaseWebAppConfig';
import Navbar from '../Navbar/Navbar';
import Pricing from '../Pricing/PricingPage';
import Footer from '../Footer/Footer';
import styles from './Home.module.css';
import { Loading } from '../../common/Loading';
import { useLoading } from '../../../context/LoadingContext';

export function Home() {
  const [showModal, setShowModal] = useState(false);
  const [reviews, setReviews] = useState([]);
  const { setInitialLoadComplete } = useLoading();
  const [isLoading, setIsLoading] = useState(true);

  const fadeInUp = {
    initial: { y: 60, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    transition: { duration: 0.6, ease: "easeOut" }
  };

  const staggerContainer = {
    animate: {
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const features = [
    {
      icon: <FaBolt />,
      title: "Real-Time Generation",
      description: "Instant SOCKS5, L2TP, and PPTP Proxy generation and regeneration capabilities."
    },
    {
      icon: <FaUsersCog />,
      title: "Custom Configuration",
      description: "Set your own username and password for complete control over your proxy setup."
    },
    {
      icon: <FaMobileAlt />,
      title: "Multi-Device Support",
      description: "Compatible with all devices including Windows, Android, iOS, and Mac."
    },
    {
      icon: <FaHeadset />,
      title: "24/7 Support",
      description: "Round-the-clock customer support via Telegram for immediate assistance."
    }
  ];

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const reviewsRef = collection(reviewsDb, 'reviews');
        const q = query(reviewsRef, orderBy('rating', 'desc'), limit(3)); // Get top 3 reviews
        const querySnapshot = await getDocs(q);
        const reviewsData = querySnapshot.docs.map(doc => {
          const data = doc.data();
          const date = data.createdAt?.toDate();
          return {
            id: doc.id,
            ...data,
            formattedDate: date ? date.toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            }) : 'Unknown Date'
          };
        });
        setReviews(reviewsData);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      } finally {
        setIsLoading(false);
        setInitialLoadComplete(true);
      }
    };
    fetchReviews();
  }, [setInitialLoadComplete]);

  if (isLoading) {
    return <Loading />;
  }

  const renderStars = (rating) => (
    <div className={styles.stars}>
      {[1, 2, 3, 4, 5].map((star) => (
        <FaStar
          key={star}
          className={`${styles.star} ${rating >= star ? styles.filled : ''}`}
        />
      ))}
    </div>
  );

  // Add these new animation variants
  const featureCardVariants = {
    offscreen: {
      y: 50,
      opacity: 0
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 1
      }
    },
    hover: {
      scale: 1.05,
      boxShadow: "0 10px 20px rgba(255, 0, 0, 0.2)",
      transition: {
        duration: 0.3
      }
    }
  };

  const reviewCardVariants = {
    offscreen: {
      y: 50,
      opacity: 0
    },
    onscreen: i => ({
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 1,
        delay: i * 0.2
      }
    }),
    hover: {
      scale: 1.03,
      rotate: [0, -1, 1, 0],
      transition: {
        duration: 0.3
      }
    }
  };

  return (
    <div className={styles.root}>
      <Navbar />
      
      {/* Warning Section - Moved to top */}
      <motion.div 
        className={styles.warning}
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.3 }}
      >
      </motion.div>

      {/* Hero Section */}
      <motion.section 
        className={styles.hero}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <motion.div className={styles.heroContent} variants={staggerContainer}>
          <motion.h1 {...fadeInUp}>
            PREMIUM BDIX BYPASS
            <span className={styles.highlight}> PROXY</span>
          </motion.h1>
          <motion.h2 {...fadeInUp} className={styles.protocols}>
            SOCKS5 • L2TP • PPTP
          </motion.h2>
          <motion.p {...fadeInUp}>
            BDIX-SPEED bypass করার জন্য সর্বোচ্চ মানের প্রক্সি সার্ভিস
          </motion.p>
          <motion.div className={styles.ctaButtons} {...fadeInUp}>
            <Link to="/signup" className={styles.primaryButton}>Get Proxy</Link>
            <Link to="/pricing" className={styles.secondaryButton}>Pricing</Link>
          </motion.div>
          <motion.div {...fadeInUp} className={styles.helpText}>
            <button onClick={() => setShowModal(true)} className={styles.helpButton}>
              <FaQuestionCircle /> How to buy proxy?
            </button>
          </motion.div>
        </motion.div>
      </motion.section>

      {/* How to Buy Modal */}
      {showModal && (
        <motion.div 
          className={styles.modalOverlay}
          onClick={() => setShowModal(false)}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <motion.div 
            className={styles.modal}
            onClick={e => e.stopPropagation()}
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ 
              scale: 1, 
              opacity: 1,
              transition: {
                type: "spring",
                stiffness: 300,
                damping: 25
              }
            }}
            exit={{ scale: 0.5, opacity: 0 }}
          >
            <motion.h2
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              How to Create Your Proxy
            </motion.h2>
            <div className={styles.steps}>
              {[1, 2, 3].map((step, index) => (
                <motion.div 
                  key={step}
                  className={styles.step}
                  initial={{ x: -50, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: 0.3 + index * 0.1 }}
                >
                  <motion.span 
                    className={styles.stepNumber}
                    whileHover={{ 
                      scale: 1.2,
                      rotate: 360,
                      transition: { duration: 0.5 }
                    }}
                  >
                    {step}
                  </motion.span>
                  {/* Keep existing step content */}
                  {step === 1 && (
                    <>
                      <h3>Signup and Login</h3>
                      <p>Create an account and login to your dashboard</p>
                    </>
                  )}
                  {step === 2 && (
                    <>
                      <h3>Add Money</h3>
                      <p>Add money to your account using available payment methods</p>
                    </>
                  )}
                  {step === 3 && (
                    <>
                      <h3>Generate Proxy</h3>
                      <p>Choose your settings:</p>
                      <motion.ul
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.6 }}
                      >
                        <li>Enter username & password for proxy</li>
                        <li>Select speed package</li>
                        <li>Choose validity period (7, 15 or 30 days)</li>
                        <li>Click "Generate"</li>
                      </motion.ul>
                      <motion.p 
                        className={styles.note}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.8 }}
                      >
                        Your proxy will be ready in 5 seconds!
                      </motion.p>
                    </>
                  )}
                </motion.div>
              ))}
            </div>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.9 }}
            >
              If you still didn't understand, you can watch video tutorials <Link to="/tutorials">here</Link>.
            </motion.p>
            <motion.button 
              className={styles.closeButton}
              onClick={() => setShowModal(false)}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1 }}
            >
              Close
            </motion.button>
          </motion.div>
        </motion.div>
      )}

      {/* Features Section */}
      <motion.section 
        className={styles.features}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
      >
        <motion.h2
          initial={{ y: -50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          Key Features
        </motion.h2>
        <div className={styles.featureGrid}>
          {features.map((feature, index) => (
            <motion.div 
              key={index}
              className={styles.featureCard}
              variants={featureCardVariants}
              initial="offscreen"
              whileInView="onscreen"
              whileHover="hover"
              viewport={{ once: true, margin: "-100px" }}
            >
              <motion.div 
                className={styles.featureIcon}
                animate={{ 
                  y: [0, -10, 0],
                  rotateY: [0, 360]
                }}
                transition={{ 
                  duration: 3,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              >
                {feature.icon}
              </motion.div>
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </motion.section>

      {/* Testimonials Section */}
      <motion.section 
        className={styles.testimonials}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
      >
        <motion.h2
          initial={{ y: -50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          What Our Clients Say
        </motion.h2>
        <div className={styles.reviewsGrid}>
          {isLoading ? (
            <div className={styles.loadingReviews}>Loading reviews...</div>
          ) : (
            reviews.map((review, index) => (
              <motion.div
                key={review.id}
                className={styles.reviewCard}
                custom={index}
                variants={reviewCardVariants}
                initial="offscreen"
                whileInView="onscreen"
                whileHover="hover"
                viewport={{ once: true, margin: "-100px" }}
              >
                <motion.div
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ delay: 0.5 + index * 0.2 }}
                >
                  <FaQuoteLeft className={styles.quoteIcon} />
                </motion.div>
                <p className={styles.reviewContent}>{review.content}</p>
                {renderStars(review.rating)}
                <div className={styles.reviewAuthor}>
                  <h4>{review.author}</h4>
                  <span>{review.formattedDate}</span>
                </div>
              </motion.div>
            ))
          )}
        </div>
        <motion.div 
          className={styles.viewAllReviews}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.6 }}
        >
          <Link to="/reviews" className={styles.secondaryButton}>View All Reviews</Link>
        </motion.div>
      </motion.section>

      <Pricing />

      {/* Warning Section */}
      <motion.section 
        className={styles.warning}
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.8 }}
      >
        <div className={styles.warningContent}>
          <FaExclamationTriangle className={styles.warningIcon} />
          <p>Warning: If Your ISP Is Dot, Amberit or Link3 Don't Purchase From This Website.</p>
        </div>
      </motion.section>
      <Footer />
    </div>
  );
}

export default Home;
