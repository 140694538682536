import { useState, useEffect } from "react";
import { Navigate, Link, useNavigate } from "react-router-dom";
import { motion } from 'framer-motion'; // Add this import
import { auth, googleprovider, db } from "../../config/firebase";
import { FaGoogle, FaArrowLeft } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    createUserWithEmailAndPassword,
    signInWithPopup,
    signOut,
    onAuthStateChanged,
    sendEmailVerification
} from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import styles from './Signup.module.css';

export const Signup = () => {
    const navigate = useNavigate();
    const [signupEmail, setSignupEmail] = useState("");
    const [signupPassword, setSignupPassword] = useState("");
    const [username, setUsername] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showRequirements, setShowRequirements] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");

    // Password requirements state
    const [passwordRequirements, setPasswordRequirements] = useState({
        minLength: false,
        hasLowerCase: false,
        hasUpperCase: false,
        hasNumber: false,
        hasSpecialChar: false,
    });

    const getErrorMessage = (error) => {
        switch (error.code) {
            case 'auth/email-already-in-use':
                return 'This email is already registered. Please try logging in instead.';
            case 'auth/invalid-email':
                return 'Please enter a valid email address.';
            case 'auth/operation-not-allowed':
                return 'Email/password sign up is not enabled. Please contact support.';
            case 'auth/weak-password':
                return 'Please choose a stronger password.';
            case 'auth/network-request-failed':
                return 'Network error. Please check your internet connection.';
            default:
                return 'An error occurred during signup. Please try again.';
        }
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                await user.reload();
                setIsLoggedIn(user.emailVerified);
                if (!user.emailVerified) {
                }
            } else {
                setIsLoggedIn(false);
            }
        });

        return () => unsubscribe();
    }, []);

    if (isLoggedIn) {
        return <Navigate to="/dashboard" />;
    }

    const saveUserDetails = async (uid, username, email) => {
        const userDocRef = doc(db, "users", uid);
        const userDoc = await getDoc(userDocRef);
        if (!userDoc.exists()) {
            await setDoc(userDocRef, {
                uid,
                username,
                email,
                balance: 0
            });
        }
    };

    const handleSignup = async () => {
        try {
            setIsLoading(true);
            setLoadingMessage("Signing up...");
            
            const userCredential = await createUserWithEmailAndPassword(auth, signupEmail, signupPassword);
            const user = userCredential.user;
            
            setLoadingMessage("Saving user details...");
            await saveUserDetails(user.uid, username, signupEmail);
            
            setLoadingMessage("Sending verification email...");
            await sendEmailVerification(user);
            
            setIsLoading(false);
            setLoadingMessage("");
            toast.success("Signup successful! Verification email sent. Please verify your email.");
            await signOut(auth);
            
            // Add auto-navigation after 10 seconds
            setTimeout(() => {
                navigate('/login');
            }, 20100);
        } catch (err) {
            setIsLoading(false);
            setLoadingMessage("");
            toast.error(getErrorMessage(err), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    const handleGoogleSignup = async () => {
        try {
            const userCredential = await signInWithPopup(auth, googleprovider);
            const user = userCredential.user;
            await saveUserDetails(user.uid, user.displayName || user.email.split("@")[0], user.email);
            toast.success("Signup with Google successful!");
        } catch (err) {
            toast.error(getErrorMessage(err), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    const handlePasswordFocus = () => setShowRequirements(true);
    const handlePasswordBlur = () => setShowRequirements(false);

    const validatePassword = (password) => {
        setPasswordRequirements({
            minLength: password.length >= 6,
            hasLowerCase: /[a-z]/.test(password),
            hasUpperCase: /[A-Z]/.test(password),
            hasNumber: /[0-9]/.test(password),
            hasSpecialChar: /[@#!%^&]/.test(password),
        });
    };

    return (
        <div className={styles.root}>
            <ToastContainer theme="dark" />
            
            <Link to="/" className={styles.backLink}>
                <FaArrowLeft /> Back to Home
            </Link>

            <motion.div 
                className={styles.signupContainer}
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3 }}
            >
                <div className={styles.formContainer}>
                    <motion.div 
                        className={styles.formHeader}
                        initial={{ y: -20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                    >
                        <h1>Create Account</h1>
                        <p>Join our community today</p>
                    </motion.div>

                    <div className={styles.inputGroup}>
                        <div className={styles.inputWrapper}>
                            <input
                                type="text"
                                placeholder="Username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>

                        <div className={styles.inputWrapper}>
                            <input
                                type="email"
                                placeholder="Email"
                                value={signupEmail}
                                onChange={(e) => setSignupEmail(e.target.value)}
                            />
                        </div>

                        <div className={styles.inputWrapper}>
                            <input
                                type="password"
                                placeholder="Password"
                                value={signupPassword}
                                onFocus={handlePasswordFocus}
                                onBlur={handlePasswordBlur}
                                onChange={(e) => {
                                    setSignupPassword(e.target.value);
                                    validatePassword(e.target.value);
                                }}
                            />
                        </div>
                    </div>

                    {showRequirements && (
                        <motion.div 
                            className={styles.requirements}
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                        >
                            {Object.entries(passwordRequirements).map(([key, met]) => (
                                <div 
                                    key={key} 
                                    className={`${styles.requirement} ${met ? styles.met : ''}`}
                                >
                                    <span className={styles.checkmark}>
                                        {met ? '✓' : '○'}
                                    </span>
                                    {key.replace(/([A-Z])/g, ' $1')
                                        .replace(/^./, str => str.toUpperCase())}
                                </div>
                            ))}
                        </motion.div>
                    )}

                    {isLoading ? (
                        <div className={styles.loadingState}>
                            <div className={styles.spinner} />
                            <p>{loadingMessage}</p>
                        </div>
                    ) : (
                        <motion.button 
                            className={styles.signupButton}
                            onClick={handleSignup}
                            whileTap={{ scale: 0.95 }}
                        >
                            Create Account
                        </motion.button>
                    )}

                    <div className={styles.divider}>
                        <span>OR</span>
                    </div>

                    <motion.button 
                        className={styles.googleButton}
                        onClick={handleGoogleSignup}
                        whileTap={{ scale: 0.95 }}
                        disabled={isLoading}
                    >
                        <FaGoogle /> Continue with Google
                    </motion.button>

                    <p className={styles.loginPrompt}>
                        Already have an account? 
                        <Link to="/login"> Sign in</Link>
                    </p>
                </div>
            </motion.div>
        </div>
    );
};

export default Signup;
