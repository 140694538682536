import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { auth } from '../../config/firebase';
import { Loading } from '../common/Loading';

const SESSION_TIMEOUT = 10 * 60 * 1000; // 10 minutes in milliseconds
const ACTIVITY_TIMER = 'lastActivityTime';

export const DashboardProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
      setIsLoading(false);
    });

    // Check for session timeout
    const checkSession = () => {
      const lastActivity = localStorage.getItem(ACTIVITY_TIMER);
      if (lastActivity && Date.now() - parseInt(lastActivity) > SESSION_TIMEOUT) {
        auth.signOut();
        localStorage.removeItem(ACTIVITY_TIMER);
        return;
      }
      localStorage.setItem(ACTIVITY_TIMER, Date.now().toString());
    };

    // Update last activity time on user interaction
    const updateActivity = () => {
      localStorage.setItem(ACTIVITY_TIMER, Date.now().toString());
    };

    // Set up event listeners for user activity
    window.addEventListener('mousemove', updateActivity);
    window.addEventListener('keydown', updateActivity);
    window.addEventListener('click', updateActivity);
    window.addEventListener('scroll', updateActivity);

    // Check session every minute
    const sessionInterval = setInterval(checkSession, 60000);

    // Initial check
    checkSession();

    return () => {
      unsubscribe();
      clearInterval(sessionInterval);
      window.removeEventListener('mousemove', updateActivity);
      window.removeEventListener('keydown', updateActivity);
      window.removeEventListener('click', updateActivity);
      window.removeEventListener('scroll', updateActivity);
    };
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return isAuthenticated ? children : <Navigate to="/login" replace />;
};
